<template>
<!--<<<<<<< HEAD-->
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col>
            <h3>ເລືອກລູກຄ້າເພື່ອສ້າງແຜນ</h3>
          </v-col>
          <v-col class="text-end">
            <div class="my-2">
              <v-btn class="btn-primary elevation-0" medium dark @click="SelectedCustomer()">
                <v-icon>mdi-plus</v-icon> ສ້າງແຜນ
              </v-btn>
            </div>
          </v-col>
        </v-row>
<!--=======-->
  <v-container>
        <v-row>
          <v-col cols="12" class="mb-4">
            <GmapMap
                v-if="customers"
                :center="getCenter()"
                :zoom="16"
                style="width: 100%; height: 500px"
                :disableDefaultUI="true"
            >
              <gmap-info-window
                  :options="infoOptions"
                  :position="infoPosition"
                  :opened="infoOpened"
                  :conent="infoContent"
                  @closeclick="infoOpened = false"
              >{{ infoContent }}
              </gmap-info-window>
              <GmapMarker
                  :key="index"
                  v-for="(m, index) in customers"
                  :position="getMarkers(m)"
                  @click="toggleInfo(m, index)"
                  :draggable="false"
                  :animation="2"
                  :clickable="true"
              />
            </GmapMap>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols>
            <v-select
                dense
                outlined
                required
                v-model="selectedDistrict"
                :items="districts"
                item-text="name"
                item-value="id"
                label="ເລືອກເມືອງ"
                multiple
            >
            </v-select>
          </v-col>
          <v-col cols>
              <v-checkbox v-model="has_route_plan"
                          @click="checkBox(has_route_plan)"
                          label="ມີແຜນເສັ້ນທາງແລ້ວ"
                          row>
              </v-checkbox>
          </v-col>
        </v-row>
    <v-row>
      <v-col>
          <v-autocomplete
              dense
              outlined
              required
              v-model="selectedVillages"
              :items="listVillages"
              item-text="name"
              item-value="id"
              label="ບ້ານ"
              multiple
          >
          </v-autocomplete>
      </v-col>
      <v-col>
        <v-text-field
            outlined
            dense
            clearable
            label="ຄົ້ນຫາ ລະຫັດລູກຄ້າ, ຊື່"
            type="text"
            v-model="search"
            @keyup.enter="Search()"
        >
        </v-text-field>
      </v-col>
    </v-row>

        <v-data-table
            :headers="headers"
            :items="customers"
            class="elevation-0"
            :loading="TableLoading"
            :disabled="TableLoading"
            :disable-pagination="true"
            hide-default-footer
            show-select
            v-model="selectedRow"
            loading-text="Loading... Please wait"
            :search="search"
        >
          <template v-slot:item.created_at="{ item }">
            <div>
              {{ item.created_at | formatDate }}
            </div>
          </template>

          <!--Action -->
          <!--      <template v-slot:item.actions="{ item }">-->
          <!--        <v-icon small class="mr-2" @click="OpenModalEdit(item)">-->
          <!--          mdi-pencil-->
          <!--        </v-icon>-->
          <!--        <v-icon small @click="deleteItem(item.id)"> mdi-delete</v-icon>-->
          <!--      </template>-->

        </v-data-table>
    <br/>
    <template>
      <Pagination
          v-if="pagination.last_page > 1"
          :pagination="pagination"
          :offset="offset"
          @paginate="fetchData()"
      ></Pagination>
    </template>
      </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import Pagination from "../../../plugins/pagination/pagination";
import manage from "../../../mixins/routeplan/create_plan";
export default {
  mixins: [manage],
  components: {
    Pagination,
  },
  metaInfo() {
    return {
      title: `ສ້າງແຜນຈັດສົ່ງ`,
    }
  },
  created() {
    this.fetchData();
    this.fetchDistrict();
    this.FilterVillages();
  }}
</script>

<style lang="scss">
@import "../../../../public/scss/main.scss";
.page--table {
.page {
&__table {
   margin-top: 20px;
 }
&__grab-icon {
   cursor: move;
 }
}
}
</style>